import React, { useState } from "react";

import DefaultLayout from "../../../../layouts/default";

const DocLMobilUnterwegs = () => {
  const [imageSwitch1, setImageSwitch1] = useState(
    "/assets/images/screenshots/l-besa/neu/Schmerz_ECPA.png"
  );
  const [imageSwitch2, setImageSwitch2] = useState(
    "/assets/images/screenshots/l-besa/neu/Kalender_pers.png"
  );

  const [imageSwitch3, setImageSwitch3] = useState(
    "/assets/images/screenshots/l-besa/neu/Medirichten_korrekt.png"
  );

  const [akkordeonOpen, setAkkordeonOpen] = useState(false);

  return (
    <DefaultLayout title="mobil unterwegs – WiCare|Doc-B">
      <div className="white wicare vanadium">
        <div className="navContentSpacerHelper"></div>
        <header className="navWrapper">
          <nav className="mainNav">
            <div className="mainNavBackgroundHelper"></div>
            <div className="logo">
              <a href="/">
                <img
                  src="/assets/images/logos/wigasoft-logo.svg"
                  alt="WigaSoft Logo"
                  width="175"
                />
              </a>
            </div>
            <div className="mainNav-opener"></div>
            <ul className="mainLinks">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/heime/">Heime</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  Rehabilitation
                </a>
              </li>
              <li>
                <a href="/portrait/">Über uns</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/wigasoft-ag">
                  <div className="linkedInIcon" />
                </a>
              </li>
            </ul>
          </nav>

          <nav className="subNav2">
            <div className="subNav2Product">
              <div className="productImage">
                <img
                  src="/assets/images/logos/WCN-Doc.svg"
                  alt="WiCare|Doc Logo"
                  width="40"
                />
              </div>
              <div className="productText">
                <span className="line">WiCare|Doc-B</span>
                <span className="line">
                  <span className="is-visible-desktop">
                    &nbsp;für Soziale Einrichtungen
                  </span>
                </span>
                {/* <span className="line">
                  <span className="is-visible-desktop">
                    &nbsp;für Langzeit-Institutionen
                  </span>
                  &nbsp;nach BESA
                </span> */}
              </div>
            </div>
            <div className="subNav2-opener"></div>
            <ul className="subNav2Links">
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-b/">Übersicht</a>
              </li>
              <li className="is-active">
                <a href="/dokumentationsloesungen/wicare-doc-b/mobile/">
                  Mobile
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-b/technik/">
                  Technik
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-b/referenzen/">
                  Referenzen
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-b/downloads/">
                  Downloads
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="menuBackground">
          <section className="mainBanner bannerWeiss">
            <div className="wrapper has-padding-top has-padding-bottom">
              <div className="bannerFlex">
                <div>
                  <img
                    className="logo-product"
                    src="/assets/images/logos/WCN-Doc.svg"
                  />
                </div>
                <div className="bannerPadding">
                  <h1>WiCare|now Doc</h1>
                  <h3 className="padding-25-bottom">mobil unterwegs</h3>
                  <p className=" bannerText">
                    WiCare|now Doc lässt Pflege dort dokumentieren, wo sie
                    anfällt, um eine auf den Menschen ausgerichtete individuelle
                    Pflege und Behandlung zu planen, zu dokumentieren und zu
                    beurteilen.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="wrapper">
            <section>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Im Jahr 2006 startete WigaSoft mit der Entwicklung der
                elektronischen Pflegedokumentation WiCare|Doc. Wir orientieren
                uns stark am Pflegeprozess und unterstützen somit eine
                professionelle Pflege vom Eintritt bis zum Austritt.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Mit neuen Technologien ergeben sich neue Möglichkeiten. Die
                WigaSoft AG setzt diesen technischen Fortschritt gezielt ein, um
                Ihren Produkten noch viel mehr Nutzen für die Anwender zu
                ermöglichen.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                WiCare|now Doc ist eine komplette Neuentwicklung der etablierten
                Pflegedokumentation WiCareDoc.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Neben der neuen Web-Technologie setzten wir altbewährte
                Funktionen noch effizienter im neuen Produkt um, ergänzt mit
                vielen neuen Funktionen und Möglichkeiten.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                WiCare|now Doc steht auf PC/Laptop, Tablet und teilweise auch
                auf dem Smartphone zur Verfügung. Somit kann das Dokumentieren
                flexibel und effizienter gestaltet werden, was der heuteigen
                Digitalisierung der Arbeitswelt gerecht wird und schlussendlich
                allen beteiligten zugutekommt.
              </p>
              <p className="fliesstextLead text-centered-td">
                Die WigaSoft AG setzt auf eine modulare Ablösung von WiCare Doc
                durch WiCare|now Doc. Es werden bestehende Module in WiCareDoc,
                z.B. der Verlaufsbericht durch neue WiCare|now Doc Komponente,
                abgelöst. Diese Schritt für Schritt Ablösung ermöglicht unseren
                Kunden einen raschen Einsatz von den Vorteilen die diese mit
                sich bringen. Schlussendlich wird die ganze Software migriert.
              </p>
            </section>
          </div>
          <div className="wrapper mobile-tablet-no-padding">
            <section className="cContainer is-background-color-blue is-color-white rounded">
              <h4 className="smallStaticPadding-bottom text-centered-td h4Bold">
                Best Features
              </h4>
              <div className="ccHalfFlex">
                <div>
                  <ul className="featuresList">
                    <li>Webbasierte, plattformunabhängige Software-Lösung</li>
                    <li>
                      Die Software passt sich Ihrem gewohnten Sprachgebrauch an
                      - z.B. wird aus Patienten, Bewohner oder Klienten usw.
                    </li>
                    <li>
                      mobiles Arbeiten ermöglicht flexibles und effizientes
                      Dokumentieren sowie innerbetriebliche Prozessoptimierungen
                    </li>
                    <li>
                      Drittapplikationen, wie bspw. Intranet, Compendium oder
                      andere Tools, lassen sich einfach via URL einbetten.
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="featuresList">
                    <li>
                      Ebenso lässt sich WiCare|now Doc mittels URL in
                      Drittapplikationen integrieren
                    </li>
                    <li>
                      Zugriffe auf Funktionalitäten im Benutzer-,
                      Bewohnergruppen- und Bewohnerkontext individuell
                      konfigurierbar
                    </li>
                    <li>
                      Lese- und Schreibrechte können rollenspezifisch definiert
                      werden
                    </li>
                    <li>
                      Unser Produktportfolio (WiCare|now LE, LEP, tacs) ist in
                      einer Applikation vereint und lässt sich beliebig
                      kombinieren
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
          <div className="wrapper has-padding-bottom">
            <img
              className="has-padding-bottom"
              src="/assets/images/screenshots/l-besa/neu/Cockpit.png"
              style={{ width: "70%", display: "block", margin: "0 auto" }}
            />
          </div>

          {/* <section id="zusatz">
            <div className="wrapper">
              <div className="has-margin-bottom">
                <p className="fliesstextS is-color-grey text-centered margin-0-auto">
                  &nbsp; Bei den mit * gekennzeichneten Komponenten handelt es
                  sich um Advanced-Komponenten, d.h. diese sind kein Bestandteil
                  des Basispakets (zusätzliche Lizenz).
                </p>
              </div>
            </div>
          </section> */}
              <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>
          <footer className="mainFooter">
            <div className="wrapper">
              <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
                <ul>
                  <li>
                    <a href="/">Startseite</a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      WiCare|Doc-B
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/wicare-doc-b/mobil/">
                      mobil unterwegs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footerNotAnotherFlexboxButAGrid linklist">
                <div className="flex-item1">
                  <ul>
                    <li>
                      <a href="/dokumentationsloesungen/heime/">
                        <strong>Heime</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/spitaeler/">
                        <strong>Spitäler</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/rehabilitation/">
                        <strong>Rehabilitation</strong>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item2">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L BESA
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L RAI-NH
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-b/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -B
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-h/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -H
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-r/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -R
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item3">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-tacs/">
                        WiCare|now tacs<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-lep/">
                        WiCare|now LEP<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-le/">
                        WiCare|now LE
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item4">
                  <ul>
                    <li>
                      <a href="/portrait/">
                        <strong>Über uns</strong>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/lehrlinge/">Lernende</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/partner/">Partner</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                    </li>
                    <li>
                      <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item5">
                  <ul>
                    <li>
                      <a href="/support/">
                        <strong>Support</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row copyright">
              <div className="wrapper">
                <div className="column">
                  © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
                </div>
                <div className="column has-padding-left">
                  <ul>
                    <li>
                      <a href="/datenschutz/">Datenschutzerklärung</a>
                    </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                  </ul>
                </div>
                <div className="column align-right">
                  <a href="/portrait/kontakt/" className="anfahrt">
                    <img
                      id="location-pointer"
                      src="/assets/images/icons/icon-anfahrt.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DocLMobilUnterwegs;
